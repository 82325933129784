import { k } from "kremling";
const toastWidth = 432;

export const listCss = k`
  & .all-toasts {
    z-index: 109995;
    position: fixed;
    bottom: 8px;
    left: calc(50vw - ${toastWidth / 2}px);
    width: ${toastWidth}px;
  }

  & .all-toasts:not(:hover) .middle .toast-left,
  & .all-toasts:not(:hover) .bottom .toast-left,
  & .all-toasts:not(:hover) .middle .toast-right,
  & .all-toasts:not(:hover) .bottom .toast-right {
    display: none;
  }

  & .all-toasts:not(:hover) .middle, & .all-toasts:not(:hover) .bottom {
    max-height: 6px;
    min-height: 6px;
    border-radius: 8px 8px 0 0;
    color: transparent;
  }

  & .all-toasts:not(:hover) .middle {
    width: ${toastWidth - 16}px;
    left: 8px;
    background-color: var(--cp-color-toast-bg);
    margin-bottom: -6px;
  }

  & .all-toasts:not(:hover) .bottom {
    width: ${toastWidth - 32}px;
    left: 16px;
    background-color: var(--cp-color-toast-hover-bg);
    margin-bottom: -6px;
  }

  & .all-toasts:hover .middle, & .all-toasts:hover .bottom {
    margin-bottom: 2px;
  }

  /* 500px should cover most mobile phone device widths */
  @media screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 599px) and (orientation: landscape) {
    & .all-toasts {
      left: 0;
      width: 100%;
    }

    & .all-toasts:not(:hover) .middle, & .all-toasts:not(:hover) .bottom {
      border-radius: 0;
    }

    & .all-toasts:not(:hover) .middle {
      width: 95%;
      left: 2.5%;
    }

    & .all-toasts:not(:hover) .bottom {
      width: 90%;
      left: 5%;
    }

    & .toast {
      margin-left: auto;
      margin-right: auto;
    }
  }

`;
export const toastCss = k`
  & .toast {
    position: relative;
    left: 0;
    padding: 6px;
    margin-bottom: 0;
    color: white;
    font-size: 12px;
    font-weight: 600;
    min-height: 32px;
    display: flex;
    width: ${toastWidth}px;
    justify-content: space-between;
    background-color: var(--cp-color-toast-bg);
    box-shadow: 0 4px 12px -2px rgba(82,85,90,0.50);
    border-radius: 8px;
    transition: height .3s ease-out, min-height .3s ease-out, max-height .3s ease-out, width .3s ease-out, left .3s ease-out, margin-bottom .3s ease-out;
  }

  & .toast:hover {
    background-color: var(--cp-color-toast-hover-bg);
  }

  & .toast-left {
    display: flex;
    align-items: center;
    cursor: default;
    overflow: hidden;
  }

  & .toast-text, .toast-link {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  & .toast-link a {
    display: block;
    color: var(--cp-color-app-primary-dark-text);
    text-decoration: underline;
    margin-bottom: .4rem
  }
  
  & .toast-link a:hover {
    text-decoration: none;
  }

  & .toast-right {
    margin-left: 20px;
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
  }

  & .toast-buttons {
    display: flex;
    align-items: center;
  }
  
  & .toast-button-unstyled {
    background: none;
    color: white;
    padding-right: .4rem;
  }

  & .toast-type-icon {
    align-self: flex-start;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .4rem .8rem 0 .4rem;
  }

  & .toast-type-icon.success {
    background-color: var(--cp-color-app-success-text);
  }

  & .toast-type-icon.warning {
    background-color: var(--cp-color-app-error-text); 
  }

  & .toast-type-icon.info {
    background-color: var(--cp-color-app-callout-text);
  }

  & .custom-action {
    font-weight: 700;
    text-decoration: underline;
    margin-right: 8px;
  }

  & .custom-action:hover, .custom-action:focus {
    text-decoration: none;
  }

  /* 500px should cover most mobile phone device widths */
  @media (max-width: 500px) {
    & .toast {
      min-height: 85px;
      width: 100%;
      border-radius: 0;
    }

    & .toast-type-icon {
      align-self: center;
    }
  }

`;
