import { ReplaySubject } from "rxjs";
import { get } from "lodash";

const DEFAULT_TOAST_DURATION = 8000;
let toastId = 0;

export const toastsStream = new ReplaySubject(1);

export function showToast(type, options) {
  if (!type) {
    throw Error(`showToast must be called with a toast type`);
  }

  if (!options) {
    throw Error(
      `showToast must be called with an options object or a list of arguments`,
    );
  }

  if (typeof options !== "object") {
    options = {
      message: get(arguments, "[1].message", get(arguments, 1)),
      actionText: get(arguments, 2),
      actionCallback: get(arguments, 3),
      durationInMillis: get(arguments, 4),
      links: get(arguments, 5, []),
    };
  }

  if (typeof options.message !== "string") {
    throw Error(
      `showToast must be called with a string message to be displayed.`,
    );
  }

  if (options.actionCallback && typeof options.actionCallback !== "function") {
    throw Error(`actionCallback for toasts must be a function`);
  }

  if (type === "warning") {
    console.error(`WARNING TOAST: ${options.message}`);
  }

  if (type === "general") {
    console.error(`GENERAL TOAST: ${options.message}`);
  }

  // Default values for options
  options.durationInMillis = options.durationInMillis || DEFAULT_TOAST_DURATION;
  options.links = options.links || [];
  options.type = type;
  options.id = toastId++;

  toastsStream.next(options);

  return {
    closeToast: () => {
      window.dispatchEvent(
        new CustomEvent("toast:close", {
          detail: {
            toast: options,
          },
        }),
      );
    },
  };
}
