import React from 'react'
import { Scoped, a, m, t } from 'kremling'
import { toastCss as css } from '../toast-css.js'
import CpButton from '../../cp-button/cp-button.component.js'
import CpIcon from '../../cp-icon/cp-icon.component.js'

const toastNameToIconName = {
  warning: "alert-exclamation",
  info: "information-large",
  success: "checkmark-large",
};

export default function Toast(props) {
  const {
    toast,
    position = "top",
    onBlur,
    onFocus,
    close,
    // limited use the toast service doesn't support this and may never support this.
    // Added for use with the offline listener/parcel
    allowManualClose = true,
  } = props;

  return (
    <Scoped css={css}>
      <div
        className={a("toast")
          .m("bottom", position === "bottom")
          .m("middle", position === "middle")
          .m("top", position === "top")}
      >
        <div className="toast-left">
          <div className={`toast-type-icon ${toast.type}`}>
            <CpIcon
              name={toastNameToIconName[toast.type]}
              size={24}
            />
          </div>
          <div className="toast-text">
            {toast.headerText && (
              <div className="cp-body cp-wt-bold">{toast.headerText}</div>
            )}
            {toast.message}
            {toast.links.map((link) => (
              <div key={link.url} className="toast-link">
                <a href={link.url}>{link.label}</a>
              </div>
            ))}
          </div>
        </div>
        <div className="toast-right">
          <div className="toast-buttons">
            {toast.actionCallback && toast.actionText && (
              <CpButton
                btnType="unstyled"
                type="button"
                onClick={(evt) => handleActionClick(evt, toast)}
                className="custom-action toast-button-unstyled"
                onBlur={onBlur}
                onFocus={onFocus}
                tabIndex={0}
              >
                {toast.actionText}
              </CpButton>
            )}
            {allowManualClose && (
              <div className="close-button">
                <CpButton
                  btnType="icon-dark"
                  icon="close-small"
                  onClick={() => close(toast.id)}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  tabIndex={0}
                >
                </CpButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </Scoped>
  );

  function handleActionClick(evt, toast) {
    toast.actionCallback();
    close(toast.id);
  }
}
