import React from "react";
import { createRoot } from "react-dom/client";
import { partial } from "lodash";
import { showToast } from "./toast-stream.js";
import ToastRoot from "./root.component.js";

export const warningToast = partial(showToast, "warning");
export const generalToast = partial(showToast, "warning");
export const infoToast = partial(showToast, "info");
export const successToast = partial(showToast, "success");

export default {
  warningToast,
  generalToast,
  infoToast,
  successToast,
};

// We render the root component immediately. It will show nothing
// until someone actually renders a toast, but will set up a subscription
// to all of the showToast function calls.
const containerDiv = document.createElement("div");
containerDiv.id = "toast-container";
document.body.appendChild(containerDiv);
const root = createRoot(containerDiv);
root.render(<ToastRoot />);
